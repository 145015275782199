.sign {
  margin-top: 12px;
  font-size: calc(10px + 2vmin);
}

.sign_modal {
  margin-top: 100px;
}

.nav-notice {
  position: relative;
  display: inline-block;
}

.nav-notice::after {
  content: "查看消息";
  position: absolute;
  bottom: -25px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 3px;
  opacity: 0;
  transition: opacity 0.3s;
  white-space: nowrap;
  font-size: small;
  font-weight: bold;
}

.nav-notice:hover::after {
  opacity: 1;
}

/* .nav-notice:hover::before {
  content: "查看消息";
  background-color: lightgray;
  color: rgb(159, 96, 201);
  font-weight: bolder;
  position: absolute;
  z-index: 1;
  padding: 5px;
  margin-bottom: 10px;
} */

.form_check {
  font-size: small;
  color: #0d6efd;
}

.forget-link {
  display: flex;
  justify-content: center;
  font-size: 14px;
  text-align: center;
  margin-bottom: 10px;
}


.third-login img {
  margin-left: 10px;
  width: 40px;
  height: 38px;
}

.link {
  font-size: 14px;
  color: dimgray;
  text-decoration: underline;
  text-underline-position: under;
}

.div_rounded {
  width: 150px;
  height: 150px;
  display: inline-block;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -khtml-border-radius: 50%;
  border: 4px solid #fff;
  margin-top: 40px;
}

.div_rounded_hidden {
  margin-top: 40px;
  display: inline-block;
}

.div_rounded img  {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -khtml-border-radius: 50%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.span_simple {
  font-size: 20px; 
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; 
  font-weight: bold;
  word-spacing: -4px;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
}

.input_file {
  margin-top: 20px;
}

.nav_dropdown_bg {
  font-size: 16px;
}

.form_edit {
  margin-top: 30px;  
}

.upload_div {
  margin-top: 50px;
  background-image: url('./images/upload.png');
  height: 80px;
}

.upload_div:hover {
  background-image: url('./images/upload_hover.png');
}

.upload_div_test {
  margin-top: 50px;
}

.upload_div_test input{
  overflow: hidden;
  filter: alpha(opacity=0);
  -moz-opacity: 0;
  -khtml-opacity: 0;
  opacity: 0;
  cursor: pointer;
}


.upload_div input{
  overflow: hidden;
  right: 0;
  top: 0;
  filter: alpha(opacity=0);
  -moz-opacity: 0;
  -khtml-opacity: 0;
  opacity: 0;
  cursor: pointer;
}


.carousel_view_img {
  width: 300px;
  height: 300px;
}

.random_txt {
  background-image: url('./images/text_bg.png');
  font-weight: 700;
}

.container_custom {
  padding-top: 50px;
  position: relative;
}

.bg_btns {
  right: 30px;
  top: 230px;
  position: fixed;
  z-index: 999;
}

.bg_btns button {
  width: 60px;
  height: 60px;
  padding: 5px 5px;
  border-radius: 35px;
  text-align: center;
  margin-bottom: 20px;
}


.list_group {
  right: 15px;
  top: 480px;
  position: fixed;
  width: 330px;
  z-index: 999;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-end;
}

.txt-area {
  display: block;
  float: right;
  width: 85px;
  font-size: 15px;
  -webkit-transition: width 0.6s ease-in-out;
  transition: width 0.6s ease-in-out;
}

.txt-area:focus {
  width: 100%;
}

.container_mb {
  margin-bottom: 300px;
}

/* .editor {
  overflow-y: scroll;
} */

.instruction {
  width: 100%;
}

.tab_content {
  margin-top: 50px;
}

.tg_btn {
  font-weight: 600;
  color: rgb(159, 96, 201);
}

.image_div {
  height: 280px;
  width: 900px;
  display: inline-block;
}

.image_div img  {
  width: 100%;
  height: 100%;
  object-fit: cover;
  vertical-align: middle;
}

.comment_btn {
  font-size: 15px;
  font-weight: 400;
  float: right;
}

.btn_send {
  transform: rotate(-45deg);
}

.comment_item {
  font-size: 14px; 
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; 
}

.comment_item img {
  height: 50px;
}

.comment_item_header {
  color: slategray;
  font-size: small;
  width: 100%;
  display: flex;
  flex: 1;
}

.comment_item_header .sl {
  display: flex;
  justify-content: flex-start;
}

.comment_item_header .sr {
  display: flex;
  justify-content: flex-end;
  flex: 1;
}

.comment_item_header a {
  font-size: 12px;
  color: dimgray;
  text-decoration: none;
}


.comment_item_footer {
  color: slategray;
  font-size: small;
}


.comment_btn_default:hover {
  cursor: pointer;
  color:skyblue;
}

.footer_btn {
  display: none;
}

.comment_item_footer:hover .footer_btn {
  display: inline-block;
}

.footer_btn:hover {
  cursor: pointer;
  color:skyblue;
}


/* .comment_item:hover {
  background-color: #dee2e6;
} */

/* .comment_item:hover .comment_item_footer_btn {
  display: block;
} */

.stack_item {
  border-top-width: 0.5px;
  border-top-color: #dee2e6;
  border-top-style: solid;
}

.sub-item img {
  height: 30px;
}

.sub_item_header {
  display: inline-block;
}

.sub-item a {
  color: dimgray;
  text-decoration: none;
}

.sub-inner {
  position: sticky;
  top: 0;
  left: 0;
  padding: 5px;
}

.chat-container {
  display: inline-flex;
  height: 70%;
}
.offcanvas-bottom {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}

.offcanvas-chat {
  margin-top: 10%;
}

.offcanvas-chat img {
  height: 36px;
  border-radius: 50%;
}

.offcanvas-chat-list {
  position: relative;
}

.chat-item {
  flex-direction: column;
  width: 73%;
}

.text-chat {
  word-wrap: break-word;
  font-size: small;
  border-radius: 0.375rem;
  background-color: #f5f7fa;
  padding: .5rem .5rem .5rem .5rem;
  display: inline-block;
}

.chat-body {
  display: inline;
  overflow-y: auto;
  width: 100%;
}

.chat-bottom {
  bottom: 0;
  position: absolute;
  width: 90%;
}

.my-chat {
  background-color: #dbe4fb;
}

.text-chat-bottom {
  display: block;
  margin-top: 5px;
  font-size: 10px;
  color: slategray;
}

/* .text-chat:hover + .text-chat-bottom {
  display: block;
  margin-top: 5px;
  font-size: 11px;
  color: slategray;
} */

.span_text {
  background-image: url('./images/text_bg.png');
  color:lavenderblush;
  word-spacing: -4px;
  font-variant: small-caps;
  font-size: 17px; 
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; 
  font-weight: bold;
  padding-left: 13px;
  padding-right: 13px;
}


.modal-common {
  margin-top: 100px;
  background-color: grey;
}

.my-modal {
  background-color:#f8f8e8;
}

.my-modal .modal-body {
  font-weight: bold;
}

.comment_title {
  background-color:#fafafb;
  border-top-color: #cacaca;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.group_item:hover { 
  background-color:#dee2e6;
}

.item_list { 
  text-align: left;
  width: 100%;
}

/* .item_list a {
  color: rgb(159, 96, 201);
} */

.item_list_badge {
  float: right;
  font-size: small;
}

.item_list_line {
  display: inline-flex;
  font-size: small;
  text-align: justify;
  width: fit-content;
}

.item_list_line img {
  width: 130px;
  height: 100px;
  margin-right: 22px;
}

.item_list_link {
  text-decoration: none;
  color: black;
}

.list_bottom {
  display: none;
}

.item_list:hover > .list_bottom {
  display: block;
}

.div_text {
  margin-top: 40px;
  text-align: left;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  white-space: pre-wrap;
  cursor: pointer;
}

.div_text_tooltip:hover::before {
  content: "联系她";
  background-color: lightgray;
  color: rgb(159, 96, 201);
  font-weight: bolder;
  position: absolute;
  z-index: 1;
  padding: 5px;
  border-style: double;
  border-radius: 30px;
  margin-left: -20px;
}

.text_gold {
  color: goldenrod;
}

.site_top {
  border-bottom: 1px dashed #cacaca;
  border-top: 1px dashed #cacaca;
  padding-top: 13px;
  padding-bottom: 13px;
  margin-bottom: 17px;
}

.site_top a {
  color: dimgray;
  text-decoration: underline;
  text-underline-position: under;
}

.site_top label {
  margin-left: 20px;
  font-size: small;
}

.site_body {
  display: inline-block;
  white-space: pre-wrap;
  text-align: left;
  padding-left: 80px;
  padding-right: 120px;
  /* margin-bottom: 300px; */
}

.li_no_style {
  display: block;
  border-top: 1px solid #cacaca;
}

.image_fluid {
  max-width: 100%;
  max-height: 500px;
  height: auto;
}

.alert_body {
  margin-top: 90px;
  margin-bottom: 200px;
}

.dd_menu {
  margin-left: 40px;
}

.dd_toggle {
  font-size: small;
}

.dd_toggle:hover > .dropdown-menu {
  display: block;
}

.tab_stack {
  background-color: lightgrey;
  box-shadow: inset 0 0 10px #ffffff;
  padding: 8px 8px 8px 8px;
}

.tab_badge:hover::before {
  content: "点击删除";
  background-color: #ccc;
  color: #000;
  position: absolute;
  z-index: 1;
  padding: 5px;
  border-radius: 5px;
  margin-top: 20px;
  margin-left: -20px;
}

.form_simple {
  width: 600px;
}

.container-padding {
  padding-top: 3rem;
  padding-bottom: 20rem;
}

.notice-list {
  font-size: 14px;
  color: dimgray;
}

.notice-list a {
  text-decoration: none;
  color: rgb(159, 96, 201);
}

.notice-list span {
  font-size: 14px;
  width: 13%;
}

.notice-list img {
  height: 56px;
  border-radius: 50%;
}

.notice-list div {
  color: black;
  text-align: start;
  width: 53%;
  padding-left: 10px;
  padding-right: 10px;
}

.notice-badge {
  margin-top: 2px;
  width: 3px;
}

.span-blank {
  width: 36px;
}