.App {
  text-align: center;
}

.App-logo {
  height: 6.5vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.app-header-fixed {
  top: 0;
  border-width: 0 0 1px;
  position: sticky;
  left: 0;
  z-index: 99999;
  width: 100%;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  height: 100px;
  background-color: white;
}


.app_background {
  background-color: ghostwhite;
  display: block;
  position: sticky;
  width:100%;
  left: 0;
  padding-bottom: 200px;
}

.header_top {
  color: black;
  height: 85px;
  font-size: 30px;
  padding-top: 5px;
}

.header_nav_main {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  height: 560px;
  padding-top: 80px;
}

/* .spotlight_inactive {
  animation: sploosh 3s cubic-bezier(0.165, 0.84, 0.44, 1);
  -webkit-animation: sploosh 3s cubic-bezier(0.165, 0.84, 0.44, 1);
  -webkit-animation: spotlightInactiveAnm 8s ease-in-out infinite;
  animation: spotlightInactiveAnm 8s ease-in-out infinite
} */

@keyframes sploosh {
  0% {
    box-shadow: 0 0 0 0px rgba(255, 220, 1, 0.7);
    background: rgba(255, 220, 1, 0.7);
  }
  100% {
    box-shadow: 0 0 0 30px rgba(255, 220, 1, 0);
    background: rgba(255, 220, 1, 0);
  }
}

/* .header_nav_201:hover .spotlight {
  background-image: radial-gradient(circle at center, transparent 20px, rgb(241, 226, 226) 100px);
  border-radius: 50%;
  position: absolute;
  transition: all .2s ease;
  right: 70px;
  width: 100px;
  height: 100px;
  overflow: hidden;
} */

.notice_badget {
  position: absolute;
  left: 70px;
  font-size: 15px;
  padding-top: 50px;
  color: dimgrey;
  font-weight: 500;
}

.header_nav_201 {
  background-image: url("../public/images/bg1.jpg");
}

.header_nav_101 {
  background-image: url("../public/images/bg2.jpg");
}

.header_nav_301 {
  background-image: url("../public/images/bg8.png");
}

.nav_tab {
  width: 380px;
  margin: auto;
  font-style: italic;
}

.nav_tab_title {
  font-weight: 500;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
}

.tab_btn_group {
  margin-top: 80px;
}

.tab_btn_group .btn {
  border-radius: 85px;
  margin-left: 33px;
}

.nav_search {
  display: inline-flex;
  width: 400px;
  margin: auto;
  border-radius: 18px;
}

.nav_search_input {
  width: 350px;
  border-radius: 18px;
  -webkit-transition: width 0.6s ease-in-out;
  transition: width 0.6s ease-in-out;
  margin-left: 10px;
}

.nav_search_input:focus {
  width: 100%;
}

.App-span {
  float: left;
  color: black;
}

.justify-content-space-around {
  justify-content: space-around;
}

.logo_nav {
   margin-top: 15px;
   font-size: calc(10px + 2vmin);
   justify-content: space-around;
}

.nav_offcanvas {
  z-index: 999999;
}

.logo_style {
  color: rgb(159, 96, 201);
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  font-style: italic;
  text-shadow: 0px 0px 6px gainsboro, 0px 0px 15px gainsboro;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.app_body {
   margin-top: 10px;
   margin-left: 80px;
   margin-right: 80px;
}

.card_list {
  transition: all 0.5s linear; 
}

.card_list:hover {
  transform: rotateY(35deg);
}

.card_list_img {
  max-height: 230px;
}

.div_pagination {
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  vertical-align: middle;
  align-items: center;
  flex-direction: column;
}

.app_select {
  width: 120px;
  color: rgb(159, 96, 201);
}

.select_option option {
  border: none;
  outline: none;
  color: rgb(159, 96, 201);
}

.tooltip_notice {
  font-size: 14px;
  color: dimgray;
}

.btn_group {
  margin-left: 10px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.edit_body {
  max-width: 42em;
  margin-top: 50px;
  margin-right: auto;
  padding-top: 20px;
  justify-content: center;
  margin-bottom: 100px;
  background-color: white;
}

.footer {
  background-color:#fafafb;
  bottom: 0px;
  position: relative;
  padding-top: 25px;
  padding-bottom: 25px;
  font-size: 14px;
}

/* .footer {
  background-color:#fafafb;
  bottom: 0px;
  position: relative;
  padding-top: 25px;
  padding-bottom: 25px;
  font-size: 14px;
} */

.footer .row{
  margin-top: 4px;
  margin-bottom: 4px;
}

.footer a{
  text-decoration: none;
  color: black;
}

.footer_row_first {
  font-weight: 700;
  padding-bottom: 10px;
  font-size: 16px;
}

.footer_row_end {
  padding-top: 10px;
}

.span_simple {
  font-size: 20px; 
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; 
  font-weight: bold;
  word-spacing: -4px;
  display: block;
  margin-bottom: 10px;
}

.span_text1 {
  background-image: url('./images/text_bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  color:lavenderblush;
  font-size: 18px; 
  font-family:'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue','Open Sans', sans-serif;
  word-spacing: -4px;
  padding-left: 28px;
  padding-right: 28px;
  width: 490px;
  align-items: center;
}

.span_text1 select {
  opacity: 0.3;
  color:darkblue;
  width: min-content;
  font-weight: bolder;
}

.search_input {
  width: auto;
  border-radius: 13px;
}

.page_view {
  background-color:lightgrey;
}

.carousel_view {
  height: 800px;
}

.phrase_view {
  float: left;
  position: fixed;
  padding-bottom: 0;
}

.search_cards {
  transition: all 0.5s ease-in-out;
}

.search_cards:hover {
  transform: scale(1.1);
}

.text-align-left {
  text-align: left;
}

.badge_small {
  font-size: 10px;
}

.card_body_span {
  font-size: 14px;
}

.card_a {
  text-decoration: none;
}

